var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('DialogCash'),_c('DialogCashIn'),_c('SelectStore'),(_vm.$route.name !== 'login')?_c('DialogAuthAlert'):_vm._e(),_c('DialogAlert',{attrs:{"msg":_vm.alertMsg}}),(
      _vm.$route.name !== 'login' &&
      _vm.$route.name !== 'autologin'
    )?_c('DialogSuccess',{attrs:{"title":_vm.propDialogSuccess.title,"text":_vm.propDialogSuccess.text,"exchange":_vm.propDialogSuccess.exchange,"back":_vm.propDialogSuccess.back,"fromNotif":_vm.propDialogSuccess.fromNotif,"new_order":_vm.propDialogSuccess.new_order,"print":_vm.propDialogSuccess.print,"printText":_vm.propDialogSuccess.printText,"printOrder":_vm.propDialogSuccess.printOrder,"printTextOrder":_vm.propDialogSuccess.printTextOrder}}):_vm._e(),_c('v-main',[_c('v-snackbar',{attrs:{"bottom":"","right":"","timeout":"-1"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
    var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":"","color":"#00f500"},on:{"click":function($event){$event.stopPropagation();return _vm.refreshApp($event)}}},'v-btn',attrs,false),[_vm._v(" "+_vm._s(_vm.snackBtnText)+" ")]),_c('v-btn',{staticClass:"ml-4",attrs:{"icon":""},on:{"click":function($event){_vm.snackWithButtons = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)]}}]),model:{value:(_vm.snackWithButtons),callback:function ($$v) {_vm.snackWithButtons=$$v},expression:"snackWithButtons"}},[_vm._v(" "+_vm._s(_vm.snackWithBtnText)+" ")]),(
        _vm.$route.name !== 'login' &&
        _vm.$route.name !== 'selectstore' &&
        _vm.$route.name !== 'autologin' &&
        _vm.$route.name !== 'menu'
      )?_c('NavbarCategory'):_vm._e(),_c('router-view')],1),_c('v-overlay',{staticClass:"text-center",attrs:{"value":_vm.$store.state.overlay,"z-index":"9999"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}}),_c('div',{staticClass:"pa-2"},[_vm._v(_vm._s(_vm.$store.state.ContentText))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }