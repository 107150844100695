import Vue from "vue"
import moment from "moment"

Vue.filter("date", function (value) {
  if (!value) return ""
  return moment(value).format("D MMMM, YYYY", "id")
})

Vue.filter("time", function (value) {
  if (!value) return ""
  return moment(value).format("HH:mm", "id")
})

Vue.filter("datetime", function (value) {
  if (!value) return ""
  return moment(value).format("D MMM, YYYY HH:mm", "id")
})

Vue.filter("dayDatetime", function (value) {
  if (!value) return ""
  moment.locale('id')
  return moment(value).format("LLLL", "id")
})

Vue.filter("day", function (value) {
  if (!value) return ""
  return moment(value).format("DD", "id")
})

Vue.filter("month", function (value) {
  if (!value) return ""
  return moment(value).format("MMM", "id")
})

Vue.filter("year", function (value) {
  if (!value) return ""
  return moment(value).format("YYYY", "id")
})

Vue.filter("millis", function (value) {
  let sec = Math.floor(value / 1000)
  let hrs = Math.floor(sec / 3600)
  sec -= hrs * 3600
  let min = Math.floor(sec / 60)
  sec -= min * 60

  sec = "" + sec
  sec = ("00" + sec).substring(sec.length)
  if (hrs > 0) {
    min = "" + min
    min = ("00" + min).substring(min.length)
    if (min > 0 && sec > 0) {
      return hrs + " jam " + min + " menit " + sec + " detik "
    } else if (min > 0 && sec < 1) {
      return hrs + " jam " + min + " menit "
    } else if (min < 1 && sec < 1) {
      return hrs + " jam "
    }
  } else if (min > 0) {
    if (sec > 0) {
      return min + " menit " + sec + " detik "
    } else {
      return min + " menit "
    }
  } else {
    return sec + " detik "
  }
})

Vue.filter("price", (num = 0) => {
  if (isNaN(num)) return "Not a Number"
  let price = ""
  const reverseNumber = num
    .toString()
    .split("")
    .reverse()
    .join("")
  const arrReverseNumber = [...Array(reverseNumber.length).keys()]
  arrReverseNumber.map(index => {
    if (index % 3 === 0) price += reverseNumber.substr(index, 3) + "."
  })

  return `Rp ${
    price.split("", price.length - 1)
    .reverse()
    .join("")
  }`
})

Vue.filter("phone", function (value) {
  if (!value) return ""
  return value.substring(0, 4) + " - XXXX - XXXX"
})

// Vue.filter("html", function (value) {
//   if (!value) return ""
//   let a = value.replace(/&lt;/g, '<')
//   // return value.replace(/<[^>]+>/g, '')
//   var stripedHtml = a.replace(/<[^>]+>/g, '');
//   var decodedStripedHtml = he.decode(stripedHtml);
//   return a.replace(/<(?:.|\n)*?>/gm, '')
// })

Vue.filter("email", function (value) {
  if (!value) return ""
  return value.substring(0, 5) + "@XXXXX"
})

Vue.filter("address", function (value) {
  if (!value) return ""
  return value.substring(0, 10) + " . . ."
})

Vue.filter("str_length", function (value) {
  if (!value) return ""
  if (value.length > 12) {
    return value.substring(0, 12) + "...";
  }

  return value;
})

Vue.filter("str_length20", function (value) {
  if (!value) return ""
  if (value.length > 20) {
    return value.substring(0, 20) + "...";
  }

  return value;
})

// for set number format
Vue.filter("number", (number = 0, decimals = 0, decPoint = ',', thousandsSep = '.') => {
  number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
  let n = !isFinite(+number) ? 0 : +number;
  let prec = !isFinite(+decimals) ? 0 : Math.abs(decimals);
  let sep = (typeof thousandsSep === 'undefined') ? ',' : thousandsSep;
  let dec = (typeof decPoint === 'undefined') ? '.' : decPoint;
  let s = '';

  let toFixedFix = function (n, prec) {
      let k = Math.pow(10, prec);
      return '' + (Math.round(n * k) / k).toFixed(prec);
  }

  s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
  if (s[0].length > 3) {
      s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
  }
  if ((s[1] || '').length < prec) {
      s[1] = s[1] || '';
      s[1] += new Array(prec - s[1].length + 1).join('0');
  }
  return s.join(dec);
})

Vue.filter("camelCase", (str = '') => {
  // str.replace(/([-_][a-z])/g, function(m){return m[1].toUpperCase()})
  return str.replace(/([-_][a-z])/g, m => `_${m[1].toLowerCase()}`)
})

Vue.filter("snakeCase", (str = '') => {
  return str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)
})

Vue.filter("emptyTextToStrip", (str = '') => {
  return str ? str : "-";
})

Vue.filter("isPng", (urlImage) => {
  let fileName = urlImage.substring(urlImage.lastIndexOf('.')+1, urlImage.length) || urlImage;
  if(fileName == "png") {
    return true
  } else {
    return false
  }
})

export default {
  moment
}