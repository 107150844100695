import Vue from 'vue'
import App from '@/App.vue'
import '@/registerServiceWorker'
import router from '@/router'
import store from '@/store'

import { TokenService } from "@/services/Storage.Service.js"
import convert from "@/plugins/Convert.js"
import '@/assets/css/main.css'
import vuetify from '@/plugins/vuetify'
import VeeValidate from "@/plugins/VeeValidate"
import VCurrencyField from 'v-currency-field'
import Carousel3d from 'vue-carousel-3d'
import { VTextField } from 'vuetify/lib'
import vueDebounce from 'vue-debounce'
import VirtualList from 'vue-virtual-scroll-list'

if (TokenService.getToken()) {
  store.commit("setToken", TokenService.getToken())
  store.commit("setSerial", TokenService.getSerialNumber())
  store.commit("setUser", JSON.parse(TokenService.getUser()))
}
Vue.component('virtual-list', VirtualList)
Vue.config.productionTip = false
Vue.component('v-text-field', VTextField)
Vue.use(vueDebounce)
Vue.use(Carousel3d)
Vue.use(VCurrencyField, { 
	locale: 'id',
	decimalLength: 0,
	autoDecimalMode: true,
	min: null,
	max: null,
	defaultValue: 0,
    valueAsInteger: false,
    allowNegative: false
})
new Vue({
  convert,
  router,
  store,
  VeeValidate,
  vuetify,
  render: h => h(App)
}).$mount('#app')
