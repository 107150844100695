const routes = [
  {
    path: "/home",
    name: "home",
    component: () => import(/* webpackChunkName: "Home" */ '@/views/Admin/Home.vue'),
    meta: {
      title: "Home"
    },
  },
  {
    path: "/menu",
    name: "menu",
    component: () => import(/* webpackChunkName: "Category" */ '@/views/Admin/Menu.vue'),
    meta: {
      title: "Menu"
    },
	},
	{
    path: "/cashiercash",
    name: "cashiercash",
    component: () => import(/* webpackChunkName: "CashierCash" */ '@/views/Admin/CashierCash'),
    meta: {
      title: "Kas Kasir"
    },
  },
  {
    path: "/closeregister",
    name: "closeregister",
    component: () => import(/* webpackChunkName: "CloseRegister" */ '@/views/Admin/CloseRegister'),
    meta: {
      title: "Tutup Shift"
    },
  },
  {
    path: "/testprinter",
    name: "testpriinter",
    component: () => import(/* webpackChunkName: "TestPrinter" */ '@/views/Admin/TestPrinter'),
    meta: {
      title: "Test Printer"
    },
  },
  {
    path: "/detailshif",
    name: "detailshif",
    component: () => import(/* webpackChunkName: "DetailCloseRegister" */ '@/views/Admin/DetailCloseRegister'),
    meta: {
      title: "Detail Shif"
    },
  },
]

export default routes.map(route => {
  const meta = {
    public: false,
		title: route.meta.title,
		layout: route.meta.layout
	}
  return { ...route, meta }
})