import Vue from "vue"
import Router from "vue-router"
import store from "@/store/index.js"
import routes from "@/router/routes/index.js"

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [{
      path: "*",
      redirect: "/home"
    },
    {
      path: "/",
      redirect: "/home"
    },
  ].concat(routes)
})

let getStore = JSON.parse(localStorage.getItem('store'))

router.beforeEach((to, from, next) => {
  const authenticated = store.state.authenticated
  const onlyLoggedOut = to.matched.some(record => record.meta.onlyLoggedOut)
  const isPublic = to.matched.some(record => record.meta.public)
  if(getStore){
    let indexStore = getStore.store.findIndex(item => item.store_id == getStore.selected_store_id)
    let dataStore = getStore.store[indexStore]
    
    // Addons
    if(!dataStore.store_have_addons_order && to.name == 'soselfOrder'){
      return next("/home")
    }
  
    if(!dataStore.store_have_addons_table && to.name == 'tablemanagement'){
      return next("/home")
    }
  }

  if (!isPublic && !authenticated) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    return next({
      path: "/login"
    })
  }
  if (authenticated && onlyLoggedOut) {
    return next("/")
  }

  document.title = `SODA | ${to.meta.title}`
  next()
})

export default router