import axios from "axios";
import store from '@/store'
import Config from "@/services/Config.js"
import { mapKeys, mapValues, camelCase, snakeCase } from "lodash";
import { TokenService } from "@/services/Storage.Service.js"

const { VUE_APP_API_BASIC } = process.env;
const VUE_APP_API_URL = Config.checkDomain()
function getAccessToken() {
  // @todo: load access token from cookie or locale storage
  return TokenService.getToken();
}

function getAccessCode() {
  // @todo: load access token from cookie or locale storage
  return TokenService.getSerialNumber();
}

function transformKeys(data, iteratee) {
  if (Array.isArray(data)) {
    return data.map((d) => transformKeys(d, iteratee));
  }

  if (data instanceof Object) {
    return mapValues(
      mapKeys(data, (_, k) => iteratee(k)),
      (v) => transformKeys(v, iteratee)
    );
  }

  return data;
}

export function camelCaseKeys(data) {
  return transformKeys(data, camelCase);
}

export function snakeCaseKeys(data) {
  return transformKeys(data, snakeCase);
}

// @see: https://github.com/mzabriskie/axios#axios-api
export function request(method, url, config = {}, options = {}) {
  const {
    params,
    data,
    headers,
    maxContentLength
  } = config;

  // non-axios specific params
  const {
    suppressAuth
  } = options;

  const baseURL = VUE_APP_API_URL;
  const BasicAuth = VUE_APP_API_BASIC;

  // @see: https://tools.ietf.org/html/rfc6750
  const Token = `${getAccessToken()}`;
  const Fcm = localStorage.getItem('TOKEN_FCM');
  const basicAuth = `Basic ${BasicAuth}`;
  const access_code= `${getAccessCode()}`;

  axios.interceptors.request.use(
    function (config) {
      // Do something before request is sent
  
      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  axios.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      if (error.response) {
        if (error.response.status == 401) {
          store.state.dialogAuthAlert = true
          store.state.networkStatus = "401"
          store.state.user = {}
          store.state.token = ""
          store.state.serial = ""
          store.state.authenticated = false

          TokenService.removeToken()

          return;
        } else if (error.response.status == 404) {
          // If request 404 (notfound)
          store.state.dialogAuthAlert = true
          store.state.networkStatus = "404"

          return;
        } else {
          // Handle if status outside 401, and 404
          store.state.dialogAuthAlert = true
          store.state.networkStatus = "anyStatus"

          return;
        }
      } else {
        store.state.dialogAuthAlert = true
        store.state.networkStatus = "anyStatus"

        return;
      }
    }
  );

  return new Promise((resolve, reject) => {
    axios({
      method,
      baseURL,
      url,
      params,
      timeout: 20000,
      data: data,
      headers: suppressAuth ? headers : { ...headers, Authorization: basicAuth, Token:Token,serial:access_code, Fcm:Fcm},
      maxContentLength
    })
      .then((response) => {
        resolve({
          ...response,
          data: response.data
        });
      })
      .catch(reject)
  });
}

export function get(url, config, options) {
  return request("GET", url, config, options);
}

export function post(url, config, options) {
  return request("POST", url, config, options);
}

export function put(url, config, options) {
  return request("PUT", url, config, options);
}

// not "delete()" because of reserved word
export function destroy(url, config, options) {
  return request("DELETE", url, config, options);
}