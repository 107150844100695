<template>
  <v-app>
    <DialogCash />
    <DialogCashIn />
    <SelectStore />
    <DialogAuthAlert v-if="$route.name !== 'login'" />
    <DialogAlert :msg="alertMsg"/>

    <DialogSuccess
      v-if="
        $route.name !== 'login' &&
        $route.name !== 'autologin'
      "
      :title="propDialogSuccess.title"
      :text="propDialogSuccess.text"
      :exchange="propDialogSuccess.exchange"
      :back="propDialogSuccess.back"
      :fromNotif="propDialogSuccess.fromNotif"
      :new_order="propDialogSuccess.new_order"
      :print="propDialogSuccess.print"
      :printText="propDialogSuccess.printText"
      :printOrder="propDialogSuccess.printOrder"
      :printTextOrder="propDialogSuccess.printTextOrder"
    />

    <!-- <v-dialog v-if="$route.name !== 'login'" persistent v-model="dialogPrintPesanan" max-width="500px">
      <v-card color="#ECEFF1" class="pa-5 rounded-lg">
        <v-row class="d-flex justify-center">
          <v-col cols="12">
            <div class="d-flex justify-center">
              <v-img
                width="230"
                height="230"
                contain
                :src="require('@/assets/saladnyooh/modal_member.png')"></v-img>
            </div>
          </v-col>
          <v-col cols="12" class="mt-5">
            <div class="d-flex justify-center">
              <div class="title-card">
                <div
                  class="text-center text-lg-h6 text-md-h6 text-sm-h6">
                  {{propDialogSuccess.title}}
                </div>
              </div>
            </div>
            <div class="d-flex justify-center mt-2">
              <span class="subtitle" v-html="propDialogSuccess.text"> </span>
            </div>
          </v-col>
          <Print color="#4CAF50" class="mt-5 mx-3 rounded-lg font-weight-bold" :label="`Print Pesanan`" :typePrint="'checkOrder'" width="45%" height="50px"/>
        </v-row>
      </v-card>
    </v-dialog> -->

    <v-main>
      <v-snackbar
        v-model="snackWithButtons"
        bottom
        right
        timeout="-1"
      >
        {{ snackWithBtnText }}
        <template v-slot:action="{ attrs }">
          <v-btn
            text
            color="#00f500"
            v-bind="attrs"
            @click.stop="refreshApp"
          >
            {{ snackBtnText }}
          </v-btn>
          <v-btn
            icon
            class="ml-4"
            @click="snackWithButtons = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>

      <NavbarCategory
        v-if="
          $route.name !== 'login' &&
          $route.name !== 'selectstore' &&
          $route.name !== 'autologin' &&
          $route.name !== 'menu'
        "
      />
      <router-view />
    </v-main>
    <v-overlay :value="$store.state.overlay" z-index="9999" class="text-center">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
      <div class="pa-2">{{$store.state.ContentText}}</div>
    </v-overlay>
  </v-app>
</template>

<script>
import { get } from "@/services/Axios";
import { TokenService, DataService } from "@/services/Storage.Service.js";
import firebaseapp from "firebase";
import firebase from "./plugins/Firebase";

const Print = () => import(/* webpackChunkName: "Print" */ '@/components/Print');
const DialogSuccess = () => import(/* webpackChunkName: "Success" */ "@/components/DialogSuccess");
const DialogCash = () => import(/* webpackChunkName: "App" */ '@/components/DialogCash');
const NavbarCategory = () => import(/* webpackChunkName: "App" */ '@/components/NavbarCategory');
const SelectStore = () => import(/* webpackChunkName: "App" */ '@/components/DialogSelectStore');
const DialogCashIn = () => import(/* webpackChunkName: "App" */ '@/components/DialogCashIn');
const DialogAuthAlert = () => import(/* webpackChunkName: "App" */ '@/components/DialogAuthAlert');
const DialogAlert = () => import(/* webpackChunkName: "App" */ '@/components/DialogAlert');

export default {
  components: {
    DialogCash,
    DialogCashIn,
    NavbarCategory,
    SelectStore,
    DialogAuthAlert,
    DialogAlert,
    DialogSuccess,
    Print
  },
  data() {
    return {
      alertMsg: "",
      snackWithButtons: false,
      snackWithBtnText:"",
      snackBtnText:"",
      refreshing: false,
      registration: null,
      dialogPrintPesanan: false,
      propDialogSuccess: {
        title: "",
        text: "",
        exchange: "",
        back: "",
        fromNotif: true,
        new_order: false,
        printOrder: true,
        printTextOrder: "",
      },
    };
  },
  computed: {
    layout() {
      return this.$route.meta.layout;
    },
    store() {
      return JSON.parse(localStorage.getItem('store'))
    },
    token () {
      return this.$store.state.token
    }
  },
  watch: {
    $route(to, from) {
      this.tokenFirebase()
    },
  },
  created() {
    this.tokenFirebase();

    var listener = new BroadcastChannel('messagePrint');
    listener.onmessage = (e) => {
      // console.log('Got message from service worker',e.data);
      this.receiveMessage(e.data)
    };

    firebaseapp.messaging().onMessage((payload) => {
      // console.log(payload.data);
      this.getTotalSelfOrder()
      if (payload.notification.body == 'Print langsung') {
        this.receiveMessage(payload)
      }
    })

    // Listen for swUpdated event and display refresh snackbar as required.
    document.addEventListener('swUpdated', this.showRefreshUI, { once: true });
    // Refresh all open app tabs when a new service worker is installed.
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (this.refreshing) return;
        this.refreshing = true;
        window.location.reload();
      });
    }
    // DataService.saveData("printConf", 'Desktop')
    this.checkCashierCash();
    this.StoreConfig()
  },
  methods: {
    tokenFirebase() {
      if (firebase.messaging.isSupported()) {
        if (!this.token) {
          navigator.permissions.query({ name: 'notifications' })
          .then(function(result) {
            if (result.state === 'granted') {
              firebaseapp.messaging().getToken()
              .then(oldToken => {
                if (oldToken === localStorage.getItem('TOKEN_FCM')) {
                  firebaseapp.messaging().deleteToken({token: oldToken})
                }
              })
            }
          })
        } else {
          navigator.permissions.query({ name: 'notifications' })
          .then(function(result) {
            if (result.state !== 'granted') {
              firebaseapp.messaging().Notification.requestPermission()
              .then(function() {
                window.location.reload()
              })
              .catch(function(err) {
                console.log('Unable to get permission to notify', err)
              })
            }
          })

          firebaseapp.messaging().getToken()
          .then(refreshedToken => {
            this.updateTokenFirebase(refreshedToken)
          })
          .catch(function(err) {
            console.log('Unable to retrieve token', err)
          })
        }
      }
    },
    async getTotalSelfOrder() {
      let params = {
        limit: 99999,
      };
      await get(`self-order/list`, {
        params,
      })
        .then((response) => {
          const res = response.data;

          if (res.status === 200) {
            this.$store.state.sales.totalSoSelfOrder = res.data.pagination.total_data;
          }
        })
        .catch((error) => {
          if (error.response) {
            let errorCode = error.response.status;

            if (errorCode === 401) {
              this.$store.commit("common/SET", {
                key: "unauthorization",
                value: true,
              });
            }
          } else {
            this.$store.commit("common/SET", {
              key: "errorNetwork",
              value: true,
            });
          }
        });
    },
    async updateTokenFirebase(refreshedToken) {
      if (refreshedToken !== localStorage.getItem('TOKEN_FCM')) {
          localStorage.setItem('TOKEN_FCM', refreshedToken)
      }
    },
    receiveMessage(dataMessage) {
      let data = JSON.parse(dataMessage.data.data)
      let dataTransaction = []

      this.store.store.forEach(item => {
        if (this.store.selected_store_id == item.store_id) {
          data.store_config_struck = item.store_config_struck
        }
      })
      // data.store_config = this.$store.state.store_config
      // data.sales_order_input_datetime = data.sales_order_date 
      // this.$store.state.sales.print.data.transaction = data
      this.$store.state.sales.print.data = data

      dataTransaction.push(this.$store.state.sales.print.data)

      dataTransaction.forEach(item => {
        if (item.sales_order_detail.length > 0) {
          this.$store.state.dialogSuccess = true;
          this.$store.state.sales.print.notificationstate = true
          this.$store.state.sales.print.notificationData.push(item)
          this.propDialogSuccess = {
            title: "Pesanan Baru",
            text: `Terdapat <i>${this.$store.state.sales.print.notificationData.length}</i> Pesanan Baru.`,
            exchange: "",
            back: "menu",
            fromNotif: true,
            new_order: false,
            printOrder: true,
            printTextOrder: "Print Semua",
          }
        }
      })

    },
    showRefreshUI(e) {
      // Display a snackbar inviting the user to refresh/reload the app due
      // to an app update being available.
      // The new service worker is installed, but not yet active.
      // Store the ServiceWorkerRegistration instance for later use.
      this.registration = e.detail;
      this.snackBtnText = 'Refresh';
      this.snackWithBtnText = 'Versi baru telah tersedia!';
      this.snackWithButtons = true;
    },
    refreshApp() {
      this.snackWithButtons = false;
      // Protect against missing registration.waiting.
      if (!this.registration || !this.registration.waiting) { return; }
      this.registration.waiting.postMessage('skipWaiting');
    },

    checkCashierCash() {
      let checkToken = TokenService.getToken();
      if (checkToken) {
        get(`cashier-cash/check`, {
          params: {},
        }).then((response) => {
          let res = response.data;
          if (res.status === 400) {
            if (res.error_code == "error_not_choose_store") {
              this.$store.state.dialogSelectStore = true;
            } else if (
              res.error_code == "error_failed" &&
              res.msg == "Anda belum mengisi kasir cash"
            ) {
              this.$store.state.dialogCashIn = true;
            } else if (res.error_code == "error_failed" && res.msg == "Silahkan pilih toko yang aktif") {
              this.$store.state.dialogAlert = true;
              this.alertMsg = res.msg
            }
          }
        });
      }
    },
    async StoreConfig(){
      await get(`store/config`).then((response) => {
        let res = response.data
        if (res.status === 200) {
          this.$store.state.store_config = res.data.store_config
        }
      })
    }
  },
};
</script>

<style>
/* .theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th{
    background-color:#EEEEEE;
  } */
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  background-color: #f5f5f5;
}
#content {
  padding: 0px;
}
#right {
  text-align: right;
}
.footer-version {
  position: absolute;
  bottom: 0.6rem;
  left: 1.5rem;
}

.theme--light.v-navigation-drawer:not(.v-navigation-drawer--floating)
  .v-navigation-drawer__border {
  background-color: inherit !important;
}

.v-dialog--active{
  border-radius: 20px;
}
</style>