const routes = [{
  path: "/al",
  name: "autologin",
  component: () => import(/* webpackChunkName: "AutoLogin" */ '@/views/Public/AutoLogin.vue'),
  meta: {
    title: "Login",
  }
}, ]

export default routes.map(route => {
  const meta = {
    public: true,
    onlyLoggedOut: false,
    title: route.meta.title,
  }
  return {
    ...route,
    meta
  }
})