import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: "",
    serial: "",
    slug: '',
    authenticated: false,
    user: {},
    networkStatus: "",
    ContentText: "",
    dialogAuthAlert: false,
    dialogAlert: false,
    dialogProduct: false,
    dialogCloseRegister: false,
    dialogCheckCash: false,
    dialogCash: false,
    dialogSplitBill: false,
    dialogDetailSalesHistory: false,
    dialogDetailSalesSelfOrder: false,
    dialogCloseRegisterPreview: false,
    dialogCustomer: false,
    dialogWANumber: false,
    dialogTable:false,
    dialogPayment: false,
    dialogCart: false,
    dialogSelectStore: false,
    dialogSelectStoreSame: false,
    dialogDiscount: false,
    dialogDiscountItem: false,
    dialogPpn: false,
    dialogService: false,
    dialogOtherCost: false,
    dialogSuccess: false,
    dialogCashIn:false,
    cashInDataRefresh: 0,
    dialogSalesHold: false,
    dialogSalesHistory: false,
    dialogSalesSelfOrder: false,
    dialogSalesTable: false,
    sales: {
      totalSoSelfOrder: 0,
      totalSoHold: 0,
      salesType: [],
      category: [],
      allproduct: [],
      productselected: {
        data: []
      },
      default: {
        discount: {
          discountPercent: 0,
          disocuntValue: 0
        },
        ppn: {
          ppnPercent: 0,
          ppnValue: 0
        },
        service: {
          servicePercent: 0,
          serviceValue: 0
        }
      },
      splitBill: {
        split: false,
        subtotal: 0,
        discount: 0,
        discountPercent: 0,
        discountItemNominal: 0,
        discountStorePercent:0,
        discountStoreNominal:0,
        ppn: 0,
        ppnPercent: 0,
        service: 0,
        servicePercent: 0,
        grandTotal: 0,
        data: []
      },
      cart: {
        fromPending : false,
        customer: {
          id: 1,
          categoryId: 1,
          name: "Umum",
          categoryName: "Umum",
          discountPercent: 0,
          discountValue: 0
        },
        table: {
          id: 0,
          name: "Meja Belum dipilih",
        },
        id: 0,
        soNumber: "",
        note: "",
        subtotal: 0,
        other_cost: 0,
        discount: 0,
        discountPercent: 0,
        discountItemNominal: 0,
        discountStorePercent:0,
        discountStoreNominal:0,
        ppn: 0,
        ppnPercent: 0,
        service: 0,
        servicePercent: 0,
        grandTotal: 0,
        data: []
      },
      print: {
        data: {},
        notificationData: [],
        notificationstate: false,
        params: "",
        soId: ""
      }
    },
    resetInput:{
      discountItem: false,
      discount: false,
      ppn: false,
      service: false
    },
    overlay: false,
    refundRefresh: false,
    salesHistoryDetail: {},
    salesSelfDetail: {},
    billSelected:[],
    selectedSalesType: {
      slug: "",
      name: "",
      img: ""
    },
    store_config:{
      other_cost: {
        label: "",
        value: "",
        is_show: false,
      }
    },

    cash: {
      runSuccess: false
    },

    closeRegister: {
      detailCloseRegister: {},
      runSuccess: false
    },

    /**
     * dialog close register
     */
    dialogDetailCloseRegister: false,

    packageVersion: process.env.VUE_APP_PACKAGE_VERSION || '0'
  },
  mutations: {
    SET(state, {
      key,
      value
    }) {
      state[key] = value;
    },
    setToken(state, accessToken) {
      state.token = accessToken
      state.authenticated = true
    },
    setUser(state, accessUser) {
      state.user = accessUser
    },
    setSerial(state, accessSerial) {
      state.serial = accessSerial
    },
  },
  actions: {},
  modules: {}
})