const routes = [{
  path: "/login",
  name: "login",
  component: () => import(/* webpackChunkName: "Login" */ '@/views/Public/Login.vue'),
  meta: {
    title: "Login",
  }
}, ]

export default routes.map(route => {
  const meta = {
    public: true,
    onlyLoggedOut: true,
    title: route.meta.title,
  }
  return {
    ...route,
    meta
  }
})