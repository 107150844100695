const TOKEN_KEY = "Token"
const USER = "user"
const SERIAL_KEY = "serial_number"
const STORE = "store"

/**
 * Manage the how Access Tokens are being stored and retreived from storage.
 *
 * Current implementation stores to localStorage. Local Storage should always be
 * accessed through this instace.
**/
const TokenService = {
  getToken() {
    return localStorage.getItem(TOKEN_KEY)
  },
  getUser() {
    return localStorage.getItem(USER)
  },
  getSerialNumber(){
    return localStorage.getItem(SERIAL_KEY)
  },
  getStore(){
    return localStorage.getItem(STORE)
  },
  saveStore(accessStore){
    localStorage.setItem(STORE, accessStore)
  },
  saveSerialNumber(accessSerial){
    localStorage.setItem(SERIAL_KEY, accessSerial)
  },
  saveToken(accessToken, accessUser) {
    localStorage.setItem(TOKEN_KEY, accessToken)
    localStorage.setItem(USER, accessUser)
  },
  saveUser(accessUser) {
    localStorage.setItem(USER, accessUser)
  },
  removeToken() {
    localStorage.removeItem(TOKEN_KEY)
    localStorage.removeItem(SERIAL_KEY)
    localStorage.removeItem(USER)
    localStorage.removeItem(STORE)
  }
}

const DataService = {
  getData(key) {
    return localStorage.getItem(key)
  },
  saveData(key,accessCategory) {
    localStorage.setItem(key, accessCategory)
  },
  removeData(key) {
    localStorage.removeItem(key)
  }
}

export { TokenService, DataService }